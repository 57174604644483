import * as React from 'react';
import Fade from 'react-reveal/Fade';
// Layouts
import { MainLayout } from '../components/Layouts/MainLayout/MainLayout';
// Section
import { HeroSection } from '../components/Section/HeroSection';
import { OurVisionSection } from '../components/Section/OurVisionSection';
import { ServiceSection } from '../components/Section/ServiceSection';
import { TestimonialSection } from '../components/Section/TestimonialSection';
import { ServiceLocation } from '../components/Section/ServiceLocation';
import { AppDownloadSection } from '../components/Section/AppDownloadSection';
// Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/general.scss';
import '../styles/typography.scss';

// markup
const IndexPage = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <MainLayout>
      <HeroSection />
      <OurVisionSection />
      <ServiceSection
        type='eats'
        title='Provides you with a variety of healthy foods that have been prepared by nutritionists to help you lose weight and stay healthy.'
      />
      <ServiceSection
        type='move'
        title='Feel the new experience of work out at your home or gym with COVID-19 protocol for your healthy lifestyle.'
      />
      <ServiceSection
        type='medic'
        title='Protect your family from the COVID-19 pandemic. Provide swab test service by a medical team that has been vaccinated and follows the health protocols recommended by the Ministry of Health of the Republic of Indonesia.'
      />
      <Fade>
        <TestimonialSection />
      </Fade>
      <ServiceLocation />
      <AppDownloadSection />
    </MainLayout>
  );
};

export default IndexPage;
