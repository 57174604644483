import React, { useState, useEffect } from 'react';
// Assets
import UncheckedImg from '../../images/unchecked.png';
import CheckedImg from '../../images/checked.png';
import { EatsCheck, MoveCheck, MedicCheck } from '../../images';

export const Checkbox = ({ value, onChange, type = 'eats' }) => {
  const [active, setActive] = useState(undefined);

  useEffect(() => {
    if (!onChange || active === undefined) return;
    onChange(active);
  }, [active, onChange]);

  useEffect(() => {
    setActive(value);
  }, [value]);

  let CheckComponent = <img src={EatsCheck} alt='checked' />;

  switch (type) {
    case 'eats':
      CheckComponent = <img src={EatsCheck} alt='checked' />;
      break;
    case 'move':
      CheckComponent = <img src={MoveCheck} alt='checked' />;
      break;
    case 'medic':
      CheckComponent = <img src={MedicCheck} alt='checked' />;
      break;

    default:
      CheckComponent = <img src={EatsCheck} alt='checked' />;
      break;
  }

  return (
    <div
      onClick={() => {
        setActive((active) => !active);
      }}
    >
      {active ? CheckComponent : <img src={UncheckedImg} alt='unchecked' />}
    </div>
  );
};
