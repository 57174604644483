export const FOOTERDATA = {
  product: {
    title: 'Product',
    menu: ['Eats', 'Fitmee', 'Move', 'Medic', '94feetofgame'],
  },
  about: {
    title: 'About',
    menu: ['Tentang FITCO', 'Store Locations', 'Reseller Program', 'Blog'],
  },
  support: {
    title: 'Support',
    menu: ['Kebijakan Privasi', 'Syarat & Ketentuan', 'Hubungi Kami', 'FAQ'],
  },
  menu: [
    {
      id: 1,
      name: 'Privacy Policy',
      link: 'https://promo.fitco.id/policy/privacy-policy',
    },
    {
      id: 2,
      name: 'Terms of Use',
      link: 'https://promo.fitco.id/policy/terms-of-use',
    },
    {
      id: 3,
      name: 'Cancellation Policy',
      link: 'https://promo.fitco.id/policy/cancellation-policy',
    },
    {
      id: 4,
      name: 'FAQ',
      link: 'https://promo.fitco.id/policy/faq',
    },
  ],
};
