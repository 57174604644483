import React, { useState, useEffect } from 'react';
// Assets
import { FitcoEats, FitcoMove, FitcoMedic, ChevronUpIcon } from '../../images';
// Styles
import './Accordion.style.scss';

export function Accordion({
  title = 'Title',
  type,
  data = [],
  expanded,
  customData,
  children,
  onClick,
  selectedId,
}) {
  const [collapse, setCollapse] = useState(expanded ? false : true);
  const isCurrentOpen = selectedId?.includes(type);

  useEffect(() => {
    if (!isCurrentOpen) {
      setCollapse(true);
    }
  }, [isCurrentOpen]);

  const renderHeading = (type) => {
    switch (type) {
      case 'eats':
        return (
          <img src={FitcoEats} alt='service-button' className='service-logo' />
        );
      case 'move':
        return (
          <img src={FitcoMove} alt='service-button' className='service-logo' />
        );
      case 'medic':
        return (
          <img src={FitcoMedic} alt='service-button' className='service-logo' />
        );

      default:
        return (
          <img src={FitcoEats} alt='service-button' className='service-logo' />
        );
    }
  };

  const activeClass = collapse ? 'collapse' : '';

  return (
    <div className='accordion-container'>
      <div
        className='accordion-container--heading ftc-flex-between-center'
        onClick={() => setCollapse((oldValue) => !oldValue)}
      >
        <div>{customData ? <h3>{title}</h3> : renderHeading(type)}</div>
        <div className='accordion-container--heading-icon'>
          <img
            src={ChevronUpIcon}
            alt='icon-button'
            className={`service-icon ${activeClass}`}
          />
        </div>
      </div>
      {!collapse && (
        <>
          {customData ? (
            <div>{children}</div>
          ) : (
            <div className='accordion-container--body'>
              {data?.map((item) => {
                const isSelected = selectedId === `${type}-${item.id}`;
                return (
                  <BranchLocationItem
                    key={item?.id.toString()}
                    item={item}
                    onClick={() => onClick(item)}
                    isSelected={isSelected}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

const BranchLocationItem = ({ item, onClick, isSelected }) => {
  const selectedClass = isSelected ? 'active' : '';
  return (
    <div className={`branch-location ${selectedClass}`} onClick={onClick}>
      <div className='branch-name ftc-flex-center'>
        <span className='title'>{item?.branch_name}</span>
      </div>
      <p className='address'>{item?.address}</p>
    </div>
  );
};
