import React from 'react';
// Media
import { Media } from '../../media/Media';
// Mock
import { GOOGLEPLAYSTORELINK, APPLEAPPSTORELINK } from '../../mock';
// Assets
import { AppPreview, FitcoLogo, GooglePlay, AppleStore } from '../../images';

export function AppDownloadSection() {
  return (
    <>
      <Media lessThan='md'>
        <MobileView />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView />
      </Media>
    </>
  );
}

const DesktopView = () => {
  return (
    <div id='download' className='app-download-container'>
      <div className='base-container'>
        <div className='ftc-flex'>
          <div className='app-download-container--left-content ftc-flex-1'>
            <img
              className='app-preview-img'
              src={AppPreview}
              alt='service_product'
            />
          </div>
          <div className='app-download-container--right-content ftc-flex-1 ftc-flex-column'>
            <h2 className='title'>DOWNLOAD APLIKASI</h2>
            <div className='ftc-flex-center'>
              <img className='fitco-img' src={FitcoLogo} alt='fitco_img' />
              <h2 className='subtitle'>INDONESIA</h2>
            </div>
            <p className='description'>
              Download Fitco Indonesia Application Fitco Indonesia is a platform
              that offers a variety of healthy lifestyle support services that
              can be easily accessed anytime, anywhere.
            </p>
            <div className='ftc-flex-center'>
              <a href={GOOGLEPLAYSTORELINK} target='_blank'>
                <img
                  className='download-app-img'
                  src={GooglePlay}
                  alt='google_play_download'
                />
              </a>
              <a href={APPLEAPPSTORELINK} target='_blank'>
                <img
                  className='download-app-img'
                  src={AppleStore}
                  alt='app_store_download'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileView = () => {
  return (
    <div id='download' className='mobile-app-download-container'>
      <div className='base-container'>
        <div className=''>
          <div className='mobile-app-download-container--left-content '>
            <img
              className='app-preview-img'
              src={AppPreview}
              alt='service_product'
            />
          </div>
          <div className='mobile-app-download-container--right-content'>
            <h2 className='title'>DOWNLOAD APLIKASI</h2>
            <div className='ftc-flex-center'>
              <img className='fitco-img' src={FitcoLogo} alt='fitco_img' />
              <h2 className='subtitle'>INDONESIA</h2>
            </div>
            <p className='description'>
              Download Fitco Indonesia Application Fitco Indonesia is a platform
              that offers a variety of healthy lifestyle support services that
              can be easily accessed anytime, anywhere.
            </p>
            <div className='ftc-flex-center'>
              <a href={GOOGLEPLAYSTORELINK} target='_blank'>
                <img
                  className='download-app-img'
                  src={GooglePlay}
                  alt='google_play_download'
                />
              </a>
              <a href={APPLEAPPSTORELINK} target='_blank'>
                <img
                  className='download-app-img'
                  src={AppleStore}
                  alt='app_store_download'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
