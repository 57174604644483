import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
// Media
import { Media } from '../../../media/Media';
// Data
import { FOOTERDATA } from '../../../utils/siteData';
// Assets
import {
  FitcoWhiteLogo,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  YoutubeIcon,
} from '../../../images';
// Mock
import {
  GOOGLEPLAYSTORELINK,
  APPLEAPPSTORELINK,
  INSTAGRAMLINK,
  LINKEDINLINK,
  YOUTUBELINK,
  FACEBOOKLINK,
} from '../../../mock';
// Styles
import './Footer.scss';

export function Footer() {
  return (
    <>
      <Media lessThan='md'>
        <MobileView />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView />
      </Media>
    </>
  );
}

const DesktopView = () => {
  return (
    <footer className='footer-container'>
      <div className='base-container'>
        <div className='row'>
          <div className='col footer-container--left-content'>
            <img
              src={FitcoWhiteLogo}
              alt='fitco-white-logo'
              className='footer-logo'
            />
            <MenuSection data={FOOTERDATA} />
          </div>
          <div className=''>
            <div className='footer-menu'>
              <span className='footer-menu--title'>Connect</span>
              <div className='footer-menu--social ftc-flex-center'>
                <a
                  href={LINKEDINLINK}
                  target='_blank'
                  className='footer-menu--social-link'
                >
                  <img src={LinkedInIcon} alt='linkedin-button' />
                </a>
                <a
                  href={INSTAGRAMLINK}
                  target='_blank'
                  className='footer-menu--social-link'
                >
                  <img src={InstagramIcon} alt='instagram-button' />
                </a>
                <a
                  href={FACEBOOKLINK}
                  target='_blank'
                  className='footer-menu--social-link'
                >
                  <img src={FacebookIcon} alt='facebook-button' />
                </a>
                <a
                  href={YOUTUBELINK}
                  target='_blank'
                  className='footer-menu--social-link'
                >
                  <img src={YoutubeIcon} alt='youtube-button' />
                </a>
              </div>
              <div className='footer-menu--download'>
                <a href={APPLEAPPSTORELINK} target='_blank'>
                  <StaticImage
                    src='../../../images/apple-store-button.png'
                    alt='apple-store-button'
                  />
                </a>
                <a href={GOOGLEPLAYSTORELINK} target='_blank'>
                  <StaticImage
                    src='../../../images/google-play-button.png'
                    alt='google-play-button'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const MobileView = () => {
  return (
    <footer className='mobile-footer-container'>
      <div className='base-container'>
        <div className='col mobile-footer-container--left-content'>
          <img
            src={FitcoWhiteLogo}
            alt='fitco-white-logo'
            className='footer-logo'
          />
          <MobileMenuSection data={FOOTERDATA} />
        </div>
        <div className=''>
          <div className='footer-menu'>
            <span className='footer-menu--title'>Connect</span>
            <div className='footer-menu--social ftc-flex-center'>
              <a
                href={LINKEDINLINK}
                target='_blank'
                className='footer-menu--social-link'
              >
                <img src={LinkedInIcon} alt='linkedin-button' />
              </a>
              <a
                href={INSTAGRAMLINK}
                target='_blank'
                className='footer-menu--social-link'
              >
                <img src={InstagramIcon} alt='instagram-button' />
              </a>
              <a
                href={FACEBOOKLINK}
                target='_blank'
                className='footer-menu--social-link'
              >
                <img src={FacebookIcon} alt='facebook-button' />
              </a>
              <a
                href={YOUTUBELINK}
                target='_blank'
                className='footer-menu--social-link'
              >
                <img src={YoutubeIcon} alt='youtube-button' />
              </a>
            </div>
            <div className='footer-menu--download'>
              <a href={APPLEAPPSTORELINK} target='_blank'>
                <StaticImage
                  src='../../../images/apple-store-button.png'
                  alt='apple-store-button'
                />
              </a>
              <a href={GOOGLEPLAYSTORELINK} target='_blank'>
                <StaticImage
                  src='../../../images/google-play-button.png'
                  alt='google-play-button'
                />
              </a>
            </div>
            <p className='copyright'>
              Copyright © 2010-2021 Fitco All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const MenuSection = ({ data }) => {
  return (
    <div className='footer-menu ftc-flex-center'>
      {data?.menu?.map((item, index) => {
        return (
          <a
            key={index.toString()}
            className='footer-menu--link'
            href={item.link}
            target='_blank'
          >
            {item.name}
          </a>
        );
      })}
    </div>
  );
};

const MobileMenuSection = ({ data }) => {
  return (
    <div className='footer-menu'>
      {data?.menu?.map((item, index) => {
        return (
          <a
            key={index.toString()}
            className='footer-menu--link'
            href={item.link}
            target='_blank'
          >
            {item.name}
          </a>
        );
      })}
    </div>
  );
};
