import React from 'react';
import { Helmet } from 'react-helmet';
// Components
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
// Styles
import './MainLayout.scss';
import '@fontsource/montserrat';
import '@fontsource/montserrat/900.css';
import '@fontsource/montserrat/600.css';

export const MainLayout = ({ children }) => {
  return (
    <div className='mainlayout-container'>
      <Helmet title='FITCO Company Profile' defer={false} />
      <Header />
      <div className='mainlayout-container-content'>{children}</div>
      <Footer />
    </div>
  );
};
