import React from 'react';
// Media
import { Media } from '../../media/Media';
// Assets
import VisionImg from '../../images/img_vision_fitco.png';

export const OurVisionSection = () => {
  return (
    <>
      <Media lessThan='md'>
        <MobileView />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView />
      </Media>
    </>
  );
};

const DesktopView = () => {
  return (
    <div id='vision'>
      <div className='our-vision-container'>
        <div className='base-container ftc-flex-center ftc-flex-1'>
          <div className='our-vision-container--left-content ftc-flex-1'>
            <img className='vision-img' src={VisionImg} alt='main_product' />
          </div>
          <div className='our-vision-container--right-content ftc-flex-1 ftc-flex-column'>
            <h2>OUR MISSION</h2>
            <p>
              To provide convenient access to health and wellness solutions.
            </p>
            <br />
            <h2>OUR VISION</h2>
            <p>Improving quality of life through health and wellness</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileView = () => {
  return (
    <div id='vision' className='mobile-our-vision-container'>
      <div className='base-container'>
        <div className='mobile-our-vision-container--left-content'>
          <img className='vision-img' src={VisionImg} alt='main_product' />
        </div>
        <div className='mobile-our-vision-container--right-content'>
          <h2>OUR MISSION</h2>
          <p>To provide convenient access to health and wellness solutions.</p>
          <br />
          <h2>OUR VISION</h2>
          <p>Improving quality of life through health and wellness</p>
        </div>
      </div>
    </div>
  );
};
