import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
// Media
import { Media } from '../../media/Media';
// Assets
import { MainProduct } from '../../images';
// Mock
import { GOOGLEPLAYSTORELINK, APPLEAPPSTORELINK } from '../../mock';

export const HeroSection = () => {
  return (
    <>
      <Media lessThan='md'>
        <MobileView />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView />
      </Media>
    </>
  );
};

const DesktopView = () => {
  return (
    <div id='intro' className='hero-section-container'>
      <div className='base-container ftc-flex-center ftc-flex-1'>
        <div className='hero-section-container--left-content ftc-flex-1 ftc-flex-column'>
          <h2>
            INDONESIA’S #1 <span className='text-blue'>DIGITAL</span>
            <br />
            <span className='text-red'>WELLNESS</span> ECOSYSTEM
          </h2>
          <p>
            FITCO is a free-to-download platform powered by an ecosystem of
            wellness services. With FITCO, everyone can easily start and
            maintain an active and healthy lifestyle.
          </p>
          <div className='ftc-flex-center'>
            <a href={GOOGLEPLAYSTORELINK} target='_blank'>
              <StaticImage
                className='download-app-img'
                src='../../images/google_play.png'
                alt='google_play_download'
              />
            </a>
            <a href={APPLEAPPSTORELINK} target='_blank'>
              <StaticImage
                className='download-app-img'
                src='../../images/app_store.png'
                alt='app_store_download'
              />
            </a>
          </div>
        </div>
        <div className='hero-section-container--right-content ftc-flex-1'>
          <img
            className='main-product-img'
            src={MainProduct}
            alt='main_product'
          />
        </div>
      </div>
    </div>
  );
};

const MobileView = () => {
  return (
    <div id='intro' className='mobile-hero-section-container'>
      <div className='base-container'>
        <div className='mobile-hero-section-container--left-content'>
          <h2>
            INDONESIA’S #1 <span className='text-blue'>DIGITAL</span>
            <span className='text-red'> WELLNESS</span> ECOSYSTEM
          </h2>
          <p>
            FITCO is a free-to-download platform powered by an ecosystem of
            wellness services. With FITCO, everyone can easily start and
            maintain an active and healthy lifestyle.
          </p>
        </div>
        <div className='mobile-hero-section-container--right-content'>
          <img
            className='main-product-img'
            src={MainProduct}
            alt='main_product'
          />
        </div>
      </div>
    </div>
  );
};
