import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Zoom from 'react-reveal/Zoom';
// Media
import { Media } from '../../media/Media';
// Components
import { Checkbox } from '../Form/Checkbox';
import { Accordion } from '../Accordion/Accordion';
// Mock
import { EATSSERVICE, MOVESERVICE, MEDICSERVICE } from '../../mock';

const getServiceData = (type) => {
  switch (type) {
    case 'eats':
      return EATSSERVICE;
    case 'move':
      return MOVESERVICE;
    case 'medic':
      return MEDICSERVICE;

    default:
      return EATSSERVICE;
  }
};

export const ServiceSection = ({ type = 'eats', title }) => {
  return (
    <>
      <Media lessThan='md'>
        <MobileView type={type} title={title} />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView type={type} title={title} />
      </Media>
    </>
  );
};

const DesktopView = ({ type = 'eats', title }) => {
  const data = getServiceData(type);
  const [selectedService, setSelectedService] = useState(data[0]);

  const renderServiceImage = () => {
    switch (type) {
      case 'eats':
        return (
          <StaticImage
            className='service-img'
            src={'../../images/fitco_eats.png'}
            alt='main_product'
          />
        );
      case 'move':
        return (
          <StaticImage
            className='service-img'
            src={'../../images/fitco_move.png'}
            alt='main_product'
          />
        );
      case 'medic':
        return (
          <StaticImage
            className='service-img'
            src={'../../images/fitco_medic.png'}
            alt='main_product'
          />
        );

      default:
        break;
    }
  };

  const renderServiceDetail = () => {
    const imageSource = selectedService?.images?.[0];
    switch (type) {
      case 'eats':
        return (
          <img
            className='service-1-img'
            src={imageSource}
            alt='service_img_1'
          />
        );
      case 'move':
        return (
          <img
            className='service-1-img'
            src={imageSource}
            alt='service_img_1'
          />
        );
      case 'medic':
        return (
          <img
            className='service-1-img'
            src={imageSource}
            alt='service_img_1'
          />
        );

      default:
        return (
          <img
            className='service-1-img'
            src={imageSource}
            alt='service_img_1'
          />
        );
    }
  };

  const renderServiceCompliment = () => {
    const imageSource = selectedService?.images?.[1];
    switch (type) {
      case 'eats':
        return (
          <img
            className='service-2-img'
            src={imageSource}
            alt='service_img_2'
          />
        );
      case 'move':
        return (
          <img
            className='service-2-img'
            src={imageSource}
            alt='service_img_2'
          />
        );
      case 'medic':
        return (
          <img
            className='service-2-img'
            src={imageSource}
            alt='service_img_2'
          />
        );

      default:
        return (
          <img
            className='service-2-img'
            src={imageSource}
            alt='service_img_2'
          />
        );
    }
  };

  return (
    <div id={`${type}-service`} className='service-wrapper'>
      <div className={`service-container ${type}`}>
        <div className='base-container'>
          <div className=''>
            {renderServiceImage()}
            <div className='service-heading'>
              <p className='service-heading--title'>{title}</p>
            </div>
            <div className='ftc-flex'>
              <Zoom>
                <div className='service-container--left-content'>
                  {data?.map((item) => {
                    const isSelected = item?.id === selectedService?.id;
                    const selectedClass = isSelected
                      ? `service-option active-${type}`
                      : 'service-option';
                    return (
                      <div
                        className={`${selectedClass} ftc-flex-center`}
                        key={item.id.toString()}
                        onClick={() => setSelectedService(item)}
                      >
                        <Checkbox value={isSelected} type={type} />
                        <label htmlFor=''>{item.name}</label>
                      </div>
                    );
                  })}
                </div>
              </Zoom>
              <Zoom>
                <div className='ftc-flex-1 ftc-flex-center'>
                  <div className='relative service-highlight'>
                    {renderServiceDetail()}
                    <div className={`service-description ${type}`}>
                      <p>{selectedService?.description}</p>
                      <button>
                        <a href={selectedService?.link} target='_blank'>
                          {selectedService?.button_label || 'Read More'}
                        </a>
                      </button>
                    </div>
                  </div>
                  {renderServiceCompliment()}
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileView = ({ type = 'eats', title }) => {
  const data = getServiceData(type);

  const renderServiceImage = () => {
    switch (type) {
      case 'eats':
        return (
          <StaticImage
            className='service-img'
            src={'../../images/fitco_eats.png'}
            alt='main_product'
          />
        );
      case 'move':
        return (
          <StaticImage
            className='service-img'
            src={'../../images/fitco_move.png'}
            alt='main_product'
          />
        );
      case 'medic':
        return (
          <StaticImage
            className='service-img'
            src={'../../images/fitco_medic.png'}
            alt='main_product'
          />
        );

      default:
        break;
    }
  };

  return (
    <div id={`${type}-service`} className={`mobile-service-container ${type}`}>
      <div className='base-container'>
        <div className=''>
          <div className='service-image'>{renderServiceImage()}</div>
          {data?.map((item, index) => {
            return (
              <Accordion
                key={item.id}
                title={item.name}
                type='eats'
                expanded={index === 0}
                customData
              >
                <div>
                  <img
                    className='service-1-img'
                    src={item?.images?.[0]}
                    alt='service_img_1'
                  />
                </div>
                <div className={`service-description ${type}`}>
                  <p>{item?.description}</p>
                  <button>
                    <a href={item?.link} target='_blank'>
                      {item?.button_label || 'Read More'}
                    </a>
                  </button>
                </div>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
};
