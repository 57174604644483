import React, { useState } from 'react';
import { Link } from 'react-scroll';
// Media
import { Media } from '../../../media/Media';
// Styles
import './Header.scss';
// Assets
import FitcoLogo from '../../../images/fitco_logo.svg';
import { MobileMenuIcon } from '../../../images';

const MAINMENU = [
  {
    id: 1,
    name: 'Intro',
    path: 'intro',
  },
  {
    id: 2,
    name: 'Our Vision',
    path: 'vision',
  },
  {
    id: 3,
    name: 'What We Do',
    path: 'eats-service',
  },
  {
    id: 4,
    name: 'Testimony',
    path: 'testimoni',
  },
  {
    id: 5,
    name: 'Locations',
    path: 'location',
  },
];

export const Header = () => {
  return (
    <>
      <Media lessThan='md'>
        <MobileView />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView />
      </Media>
    </>
  );
};

const DesktopView = () => {
  return (
    <header className='header-container'>
      <div className='ftc-flex base-container'>
        <img src={FitcoLogo} alt='fitco_logo' />
        <div className='header-menu ftc-flex-center'>
          {MAINMENU?.map((item) => {
            return (
              <Link
                key={item.id.toString()}
                className='header-menu-link'
                activeClass='active'
                to={item.path}
                spy={true}
                smooth={true}
              >
                {item.name}
              </Link>
            );
          })}
          <Link
            className='header-menu-downloadlink'
            activeClass='active'
            to='download'
            spy={true}
            smooth={true}
          >
            Download App
          </Link>
        </div>
      </div>
    </header>
  );
};

const MobileView = () => {
  const [collapse, setCollapse] = useState(true);

  return (
    <header className='mobile-header'>
      <div className='mobile-header-container'>
        <div className='fitco-logo'>
          <img src={FitcoLogo} alt='fitco_logo' />
        </div>
        <div onClick={() => setCollapse((oldValue) => !oldValue)}>
          <img src={MobileMenuIcon} alt='header_menu' />
        </div>
      </div>
      {!collapse && (
        <div className='mobile-header-menu'>
          {MAINMENU?.map((item) => {
            return (
              <Link
                key={item.id.toString()}
                className='mobile-header-menu-link'
                activeClass='active'
                to={item.path}
                spy={true}
                smooth={true}
              >
                {item.name}
              </Link>
            );
          })}
          <Link
            className='mobile-header-menu-link'
            activeClass='active'
            to='download'
            spy={true}
            smooth={true}
          >
            Download App
          </Link>
        </div>
      )}
    </header>
  );
};
