import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export function SliderCarousel({ children }) {
  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
