import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
// Media
import { Media } from '../../media/Media';
// Components
import { Accordion } from '../Accordion/Accordion';
// Assets
import { PinLocation } from '../../images';
// Mock
import {
  FITCOEATSBRANCHLOCATION,
  FITCOMOVEBRANCHLOCATION,
  FITCOMEDICBRANCHLOCATION,
} from '../../mock';

const defaultProps = {
  center: {
    lat: -6.24129942538839,
    lng: 106.81501445495392,
  },
  zoom: 16,
};

export function ServiceLocation() {
  return (
    <>
      <Media lessThan='md'>
        <MobileView />
      </Media>
      <Media greaterThanOrEqual='md'>
        <DesktopView />
      </Media>
    </>
  );
}

const Marker = (props) => {
  return (
    <div className='pin-location-container'>
      <img src={PinLocation} alt='pin-location' />
    </div>
  );
};

const DesktopView = () => {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: -6.24129942538839,
    lng: 106.81501445495392,
  });
  const [selectedId, setSelectedId] = useState('eats-1');

  return (
    <div id='location' className='service-location-container'>
      <div className='base-container ftc-flex'>
        <div className='service-location-container--left-content ftc-flex-1 ftc-flex-column'>
          <Accordion
            type='eats'
            data={FITCOEATSBRANCHLOCATION}
            expanded
            onClick={(item) => {
              setSelectedId(`eats-${item.id}`);
              setSelectedLocation(item?.coordinate);
            }}
            selectedId={selectedId}
          />
          <Accordion
            type='move'
            data={FITCOMOVEBRANCHLOCATION}
            onClick={(item) => {
              setSelectedId(`move-${item.id}`);
              setSelectedLocation(item?.coordinate);
            }}
            selectedId={selectedId}
          />
          <Accordion
            type='medic'
            data={FITCOMEDICBRANCHLOCATION}
            onClick={(item) => {
              setSelectedId(`medic-${item.id}`);
              setSelectedLocation(item?.coordinate);
            }}
            selectedId={selectedId}
          />
        </div>
        <div className='service-location-container--right-content ftc-flex-1'>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyABDaH5ydHvaaKP5_hFZdS4Ys8Mu4eN28A',
            }}
            defaultCenter={selectedLocation}
            defaultZoom={defaultProps.zoom}
            center={selectedLocation}
          >
            <Marker lat={selectedLocation.lat} lng={selectedLocation.lng} />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
};

const MobileView = () => {
  return (
    <div id='location' className='mobile-service-location-container'>
      <div className='base-container ftc-flex'>
        <div className='mobile-service-location-container--left-content ftc-flex-1 ftc-flex-column'>
          <h3>Visit Our Location</h3>
          <Accordion type='eats' data={FITCOEATSBRANCHLOCATION} expanded />
          <Accordion type='move' data={FITCOMOVEBRANCHLOCATION} />
          <Accordion type='medic' data={FITCOMEDICBRANCHLOCATION} />
        </div>
      </div>
    </div>
  );
};
