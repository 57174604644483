import {
  Express1,
  Express2,
  Catering1,
  Catering2,
  Fitmee1,
  Fitmee2,
  Gym1,
  Gym2,
  Class1,
  Class2,
  PT1,
  PT2,
  Virtual1,
  Virtual2,
  Drivethru1,
  Drivethru2,
  Homeservice1,
  Homeservice2,
  Coorporate1,
  Coorporate2,
  Wedding1,
  Wedding2,
  Testimonial1,
  Testimonial2,
  Testimonial3,
} from '../images';

export const EATSSERVICE = [
  {
    id: 1,
    name: 'Express',
    images: [Express1, Express2],
    link: 'https://promo.fitco.id/lead/eat',
    description:
      'Enjoy convenient, delicious, and low-calorie menus of the day without MSG. Fueling your day with healthy stuff, so you can be out there enjoying the thing you love without feeling guilty',
  },
  {
    id: 2,
    name: 'Catering',
    images: [Catering1, Catering2],
    link: 'https://promo.fitco.id/lead/eat',
    description:
      'Get ready to support your day with healthy catering with a wide variety of flavors. Hygienically processed with high-quality ingredients that have been measured by nutritionists.',
  },
  {
    id: 3,
    name: 'Fitmee',
    images: [Fitmee1, Fitmee2],
    link: 'https://promo.fitco.id/lead/eat',
    description:
      'Instant noodles with low calories and high fiber are wrapped in a delicious aroma and taste. Made from shirataki and konjac sweet potato with various flavors.',
  },
];

export const MOVESERVICE = [
  {
    id: 1,
    name: 'GYM',
    images: [Gym1, Gym2],
    link: 'https://promo.fitco.id/lead/move',
    description:
      'Work out in our private gym, which is equipped with high-quality fitness equipment. Implementation of high hygiene and safety standards in accordance with COVID19',
  },
  {
    id: 2,
    name: 'Class',
    images: [Class1, Class2],
    link: 'https://promo.fitco.id/lead/move',
    description:
      'Join the excitement of classes guided by a Professional Coach; such as Yoga, Fitdance, Fitcycle, Muay Thai, Queenax, Zumba, and others.',
  },
  {
    id: 3,
    name: 'Personal Coaching',
    images: [PT1, PT2],
    link: 'https://promo.fitco.id/lead/move',
    description:
      'Providing individually certified personal trainers to assist you in your workout to achieve your lifestyle goals.',
  },
  {
    id: 4,
    name: 'Virtual',
    images: [Virtual1, Virtual2],
    link: 'https://promo.fitco.id/lead/move',
    description:
      'Take part in exciting courses led by Professional Coach wherever you are. With a capacity of 20 people, it easily helps the coach to monitor and control movements to be more efficient and effective.',
  },
];

export const MEDICSERVICE = [
  {
    id: 1,
    name: 'Drive-Thru',
    images: [Drivethru1, Drivethru2],
    link: 'https://medic.fitco.id/medic/checkup/covid-19',
    button_label: 'Book Now',
    description:
      'Easily perform COVID-19 swab checks without leaving the vehicle at several points locations in Jakarta.',
  },
  {
    id: 2,
    name: 'Home Services',
    images: [Homeservice1, Homeservice2],
    link: 'https://medic.fitco.id/medic/checkup/covid-19',
    button_label: 'Book Now',
    description:
      'Enjoy the COVID19 test service at home with our medical team in a safe, convenient, and practical way.',
  },
  {
    id: 3,
    name: 'Corporate',
    images: [Coorporate1, Coorporate2],
    link: 'https://api.whatsapp.com/send?phone=6281112427900&text=Hi, saya ingin menanyakan tentang FITCO - Medic Corporate',
    button_label: 'Contact Us',
    description:
      'Ensure the health of your employees and clients is maintained with this corporate service',
  },
  {
    id: 4,
    name: 'Wedding / Event',
    images: [Wedding1, Wedding2],
    link: 'https://api.whatsapp.com/send?phone=6281112427900&text=Hi, saya ingin menanyakan tentang FITCO - Medic Wedding',
    button_label: 'Contact Us',
    description:
      'Prepare your event to be safe and comfortable. Make sure your relatives and family are safe from COVID-19.',
  },
];

export const TESTIMONI = [
  {
    id: 1,
    name: 'Jovi Adhiguna',
    occupation: 'Artist',
    testimoni:
      'Dari kemarin aku mencoba untuk mengurangi konsumsi makanan kurang sehat. Akhirnya aku coba untuk konsumsi daily catering dengan program weight loss dari FITCO Eats. Satu porsi cateringnya ada sayur mayur, daging, karbo, pilihan sayuran dan juga buah-buahan. Dipackagingnya juga tertulis nutritions fact per serving loh. Yaitu jumlah kalori protein, karbohidrat, serat dan total fat. Rasanya enak!',
    service: 'eats',
    image_url: Testimonial1,
  },
  {
    id: 2,
    name: 'Merrie Elizabeth',
    occupation: 'Entrepreneur',
    testimoni:
      'Karena sibuk bekerja, aku tidak sempat mengihuting jumlah kalori yang aku konsumsi, dengan catering sehat ini, aku sangat terbantu karena makanan yang aku konsumsi sudah ditakar jumlah kalorinya secara mendetail mulai dari protein, karbohidrat fiber dan fat. Makanannya enak banget, isinya ada aneka daging untuk protein harian ditambahkan dengan olahan sayur yang variatif. Jadi gak sabar deh nunggu catering datang tiap hari.',
    service: 'eats',
    image_url: Testimonial2,
  },
  {
    id: 3,
    name: 'Jonathan Hindharta',
    occupation: 'Health Enthusiast',
    testimoni:
      'VirtuFit bareng Coach William, sesinya seru dan asyik! Efeknya bikin badan jadi lebih segar! Kelas VirtuFit sangat membantu di masa pandemic ini, membuat saya tetap bisa olahraga tanpa keluar rumah, dan juga ada Personal Trainer yang mengarahkan sehingga membuat olahraga kita lebih maksimal.',
    service: 'move',
    image_url: Testimonial3,
  },
];

export const BRANCHLOCATION = [
  {
    id: 1,
    branch_name: 'Gedung Panin Pusat',
    service: 'move',
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta',
  },
  {
    id: 2,
    branch_name: 'Central Kitchen',
    service: 'eats',
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta',
  },
  {
    id: 3,
    branch_name: 'Pusat Grosir Cililitan',
    service: 'medic',
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta',
  },
  {
    id: 4,
    branch_name: 'Central Kitchen',
    service: 'eats',
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta',
  },
  {
    id: 5,
    branch_name: 'Pusat Grosir Cililitan',
    service: 'medic',
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta',
  },
];

export const FITCOEATSBRANCHLOCATION = [
  {
    id: 1,
    branch_name: 'Fitco Eats Wijaya',
    service: 'eats',
    coordinate: {
      lat: -6.24129942538839,
      lng: 106.81501445495392,
    },
    address:
      'Jl. Wijaya Timur Raya No.29, RW.2, Petogogan, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12170',
  },
  {
    id: 2,
    branch_name: 'Gedung Panin',
    service: 'eats',
    coordinate: {
      lat: -6.227467810974097,
      lng: 106.80051777349856,
    },
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10270',
  },
  {
    id: 3,
    branch_name: 'Gelora Bung Karno',
    service: 'eats',
    coordinate: {
      lat: -6.217218542065763,
      lng: 106.80260750934325,
    },
    address:
      'Gelora Bung Karno Sports Complex, Ring road Stadion Utama, Gate G No.10, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10270',
  },
  {
    id: 4,
    branch_name: 'FITCO Eats Senayan',
    service: 'eats',
    coordinate: {
      lat: -6.23361786280073,
      lng: 106.7929290140664,
    },
    address:
      'Jl. Sinabung Jl. Iskandarsyah Raya No.9, RT.8/RW.5, Gunung, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12120',
  },
  {
    id: 5,
    branch_name: 'FITCO Eats Cipete',
    service: 'eats',
    coordinate: {
      lat: -6.27747148806369,
      lng: 106.79913386338079,
    },
    address:
      'Jl. Cipete Raya No.16, RT.5/RW.4, Cipete Sel., Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12410',
  },
  {
    id: 6,
    branch_name: 'FITCO Eats BTPN',
    service: 'eats',
    coordinate: {
      lat: -6.229639687997441,
      lng: 106.82763728111622,
    },
    address:
      'Menara BTPN, Jl. Dr. Ide Anak Agung Gde Agung Kav 5.5 - 5.6, CBD, RT.5/RW.2, Kuningan, East Kuningan, Setiabudi, South Jakarta City, Jakarta 12950',
  },
  {
    id: 7,
    branch_name: 'FITCO Eats Cibis',
    service: 'eats',
    coordinate: {
      lat: -6.295381999636678,
      lng: 106.81373465495436,
    },
    address:
      'Jl. TB Simatupang No 2, Cilandak Timur, Pasar Minggu, Jakarta Selatan 12560',
  },
];

export const FITCOMOVEBRANCHLOCATION = [
  {
    id: 1,
    branch_name: 'Gedung Panin',
    service: 'move',
    coordinate: {
      lat: -6.227467810974097,
      lng: 106.80051777349856,
    },
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10270',
  },
];

export const FITCOMEDICBRANCHLOCATION = [
  {
    id: 1,
    branch_name: 'Gedung Panin',
    service: 'medic',
    coordinate: {
      lat: -6.227467810974097,
      lng: 106.80051777349856,
    },
    address:
      'Jl. Jend. Sudirman No.Kav.1, RT.1/RW.3, Gelora, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10270',
  },
];

export const GOOGLEPLAYSTORELINK =
  'https://play.google.com/store/apps/details?id=com.twenty.fit&hl=en&gl=US';
export const APPLEAPPSTORELINK =
  'https://apps.apple.com/id/app/fitco-indonesia/id1475504793';
export const INSTAGRAMLINK = 'https://www.instagram.com/fitco.id/';
export const LINKEDINLINK = 'https://www.linkedin.com/company/thefitcompany';
export const WHATSAPPLINK = 'https://api.whatsapp.com/send?phone=6281112427900';
export const FACEBOOKLINK = 'https://www.facebook.com/FitcoIndonesia';
export const YOUTUBELINK =
  'https://www.youtube.com/channel/UC8zo7jvEtFzX6LBvg-JapdQ';
