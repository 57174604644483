import React from 'react';
// Components
import { SliderCarousel } from '../../components/Slider/Slider';
// Mock
import { TESTIMONI } from '../../mock';
// Assets
import FitcoEats from '../../images/fitco_eats.png';
import FitcoMove from '../../images/fitco_move.png';
import FitcoMedic from '../../images/fitco_medic.png';

export const TestimonialSection = () => {
  return (
    <div id='testimoni'>
      <div className='testimonial-container'>
        <SliderCarousel>
          {TESTIMONI?.map((item) => {
            return <TestimoniItem key={item.id.toString()} item={item} />;
          })}
        </SliderCarousel>
      </div>
    </div>
  );
};

const TestimoniItem = ({ item }) => {
  const renderServiceImage = (service) => {
    switch (service) {
      case 'eats':
        return (
          <img className='service-img' src={FitcoEats} alt='main_product' />
        );
      case 'move':
        return (
          <img className='service-img' src={FitcoMove} alt='main_product' />
        );
      case 'medic':
        return (
          <img className='service-img' src={FitcoMedic} alt='main_product' />
        );

      default:
        break;
    }
  };

  return (
    <div className='testimonial-container--item'>
      <img
        className='user-image'
        src={item?.image_url}
        alt='testimoni_user_image'
      />
      <span className='title'>{item.name}</span>
      <span className='occupation'>{item.occupation}</span>
      <p className='description'>{item.testimoni}</p>
      {renderServiceImage(item.service)}
    </div>
  );
};
